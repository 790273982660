import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { lazy, Suspense, useEffect, useState } from "react";

import nav from "./data/nav.json";

const Template = lazy(() =>
  import("./components/layout/template/template.component")
);

const Home = lazy(() => import("./pages/home/home.page"));

function App() {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const arrRoutes = nav.reduce((acc, { id, url, page, details }) => {
      const url_string = url.charAt(0) === "/" ? url.substring(1) : url;
      if (!details) {
        if (!url_string.startsWith("#")) {
          const Page = lazy(() => import(`./pages/${page}/${page}.page`));
          return [
            ...acc,
            <Route path={url_string} element={<Page />} key={id} />,
          ];
        }
      } else {
        const sub_url_string =
          details.url.charAt(0) === "/"
            ? details.url.substring(1)
            : details.url;
        if (!sub_url_string.startsWith("#")) {
          const Page = lazy(() => import(`./pages/${page}/${page}.page`));
          const SubPage = lazy(() =>
            import(`./pages/${details.page}/${details.page}.page`)
          );
          return [
            ...acc,
            <Route path={url_string} element={<Page />} key={id} />,
            <Route
              path={sub_url_string}
              element={<SubPage />}
              key={details.id}
            />,
          ];
        }
      }
      return acc;
    }, []);
    setRoutes(arrRoutes);
  }, []);

  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={5000} theme="colored" />
      <Suspense
        fallback={
          <div className="loader">
            <span className="fa fa-spinner fa-spin"></span>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Template />}>
            <Route index element={<Home />} />
            {routes}
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
